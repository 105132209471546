import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

const testimonial = [
    {
        Des: "Ik ben erg onder de indruk van de professionele aanpak van het team. Ze leverden uitstekend werk en overtroffen mijn verwachtingen. Ik zou hen zeker aanbevelen aan anderen.",
        Title: 'Sophie de Vries',
        Sub: "Marketing Manager",
    },
    {
        Des: "De service die ik heb ontvangen was uitzonderlijk. Het team was zeer responsief en stond altijd klaar om mijn vragen te beantwoorden. Ik ben zeer tevreden met het eindresultaat en kijk uit naar toekomstige samenwerkingen.",
        Title: 'Johan Petersen',
        Sub: "Eigenaar van een klein bedrijf",
    },
    {
        Des: "Ik had het genoegen om met dit team samen te werken en ik ben onder de indruk van hun toewijding aan kwaliteit. Ze luisterden goed naar mijn wensen en leverden een product dat perfect aan mijn behoeften voldeed. Ik zou zeker weer met hen samenwerken in de toekomst.",
        Title: 'Anna van Dijk',
        Sub: "Freelance Schrijver",
    }
]

const Testimonial = () => {
    return (
        <section className="wpo-testimonials-section section-padding">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5">
                        <div className="wpo-section-title">
                            <h2>Wat klanten zeggen? </h2>
                            <p> Ontdek hoe onze diensten gewaardeerd worden door te luisteren naar ervaringen van tevreden klanten.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-testimonials-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <div className="wpo-testimonials-img">
                                <img src={'https://cleener-react.wpocean.com/static/media/testimonial.1be33a31.jpg'} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="wpo-testimonials-text">
                                <div className="quote">
                                    <i className="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                                <div className="wpo-testimonials-slide owl-carousel">
                                    <Slider {...settings}>
                                        {testimonial.map((tesmnl, tsm) => (
                                            <div className="wpo-testimonials-slide-item" key={tsm}>
                                                <p>{tesmnl.Des}</p>
                                                <h5>{tesmnl.Title}</h5>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;