import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import emailjs from "@emailjs/browser";

const Appointment = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();

      // Send email
      emailjs
        .send(
          "service_ozsx57f",
          "template_w6uj6im",
          {
            from_name: forms.name,
            from_email: forms.email,
            phone: forms.phone,
            subject: forms.subject,
            message: forms.message,
          },
          "Y3xjlBaVABiCV6XUx"
        )
        .then(
          (response) => {
            alert("Thank you. we will get back to you as soon as possible.");
            console.log("Email sent successfully:", response);
            // Clear the form after successful submission
            setForms({
              name: "",
              email: "",
              subject: "",
              phone: "",
              message: "",
            });
          },
          (error) => {
            alert("Ahh, something went wrong. Please try again.");
            console.error("Email send failed:", error);
          }
        );
    } else {
      validator.showMessages();
    }
  };

  return (
    <section className="wpo-contact-section section-padding">
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title-s2">
                  <h2>Maak een afspraak</h2>
                </div>
                <form
                  onSubmit={(e) => submitHandler(e)}
                  className="contact-validation-active"
                >
                  <div className="row">
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.name}
                          type="text"
                          name="name"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Je Naam"
                        />
                      </div>
                      {validator.message(
                        "name",
                        forms.name,
                        "required|alpha_space"
                      )}
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.email}
                          type="email"
                          name="email"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Je E-mail"
                        />
                        {validator.message(
                          "email",
                          forms.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={forms.phone}
                          type="phone"
                          name="phone"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Je phone"
                        />
                        {validator.message(
                          "phone",
                          forms.phone,
                          "required|phone"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-field">
                        <select
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.subject}
                          type="text"
                          name="subject"
                        >
                          <option>Kies een Dienst</option>
                          <option>Residentiële Schoonmaak</option>
                          <option>Commerciële Schoonmaak</option>
                          <option>Kantoor Schoonmaak</option>
                          <option>Thuis Schoonmaak</option>
                          <option>Winkel Schoonmaak</option>
                          <option>Weg Schoonmaak</option>
                          <option>Auto Schoonmaak</option>
                        </select>
                        {validator.message(
                          "subject",
                          forms.subject,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                    <div className="col fullwidth col-lg-12 ">
                      <textarea
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Bericht"
                      ></textarea>
                      {validator.message("message", forms.message, "required")}
                    </div>
                  </div>
                  <div className="submit-area">
                    <button type="submit" className="theme-btn">
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Verzenden
                    </button>
                  </div>
                </form>
                <div className="border-style"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wpo-contact-img">
                <img
                  src={
                    "https://cleener-react.wpocean.com/static/media/contact.a8083498.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
