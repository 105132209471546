import React from "react";
import { Link } from "react-router-dom";
import blog6 from "../../images/blog-details/author.jpg";
import blogs from "../../api/blogs";
import { useParams } from "react-router-dom";
import BlogSidebar from "../BlogSidebar/BlogSidebar.js";

const BlogSingle = (props) => {
  const { id } = useParams();

  const BlogDetails = blogs.find((item) => item.id === id);


  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{" "}
                      <Link to="/blog-single/1">{BlogDetails.author}</Link>{" "}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{" "}
                      Comments {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{" "}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>{BlogDetails.title}</h2>
                <p>
                  Er zijn veel variaties van passages over schoonmaken
                  beschikbaar, maar het merendeel heeft op de een of andere
                  manier veranderingen ondergaan, door ingevoegde humor of
                  willekeurige woorden die zelfs niet geloofwaardig lijken. Als
                  je een passage over schoonmaken gaat gebruiken, moet je er
                  zeker van zijn dat er niets gênants verborgen zit in de tekst.
                  Alle schoonmaaktekstgeneratoren op internet hebben de neiging
                  om vooraf gedefinieerde stukken te herhalen zoals nodig,
                  waardoor dit de eerste echte generator op internet is. Het
                  maakt gebruik van een woordenboek van meer dan 200 woorden
                  over schoonmaken, in combinatie met een handvol.
                </p>
                <blockquote>
                  Gecombineerd met een handvol modelzinstructuren, genereer
                  Lorem Ipsum die redelijk lijkt. De gegenereerde tekst over
                  schoonmaken is daarom altijd vrij van herhaling, ingevoegde
                  humor of niet-karakteristieke woorden, enz.
                </blockquote>
                <p>
                  Ik moet uitleggen hoe dit hele misleide idee om schoonmaken te
                  veroordelen en te prijzen is ontstaan en ik zal je een
                  volledig verslag geven van het systeem, en de werkelijke
                  leerstellingen van de grote ontdekkingsreiziger van de
                  waarheid, de meesterbouwer van menselijk geluk. Niemand wijst,
                  vindt niet leuk, of vermijdt schoonmaken op zichzelf,
                </p>

                <div className="gallery">
                  <div>
                    <img
                      src={
                        "https://cleener-react.wpocean.com/static/media/img-3.fc32c2e4.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={
                        "https://cleener-react.wpocean.com/static/media/img-2.ba4f2f97.jpg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Share: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">Plumbing</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">Window</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">Kitchen</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tag-share-s2 clearfix">
                <div className="tag">
                  <span>Share: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">facebook</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">twitter</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">linkedin</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">pinterest</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/blog-single/1" target="_blank">
                    <img src={blog6} alt="" />
                  </Link>
                </div>
                <div className="author-content">
                  <Link to="/blog-single/1" className="author-name">
                    Author: Jenny Watson
                  </Link>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis.
                  </p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="/blog-single/1">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-single/1">
                          <i className="ti-twitter-alt"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-single/1">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-single/1">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="more-posts">
                <div className="previous-post">
                  <Link to="/blog">
                    <span className="post-control-link">
                      Vorige Blogbericht
                    </span>
                    <span className="post-name">
                      Bij het schoonmaken is het belangrijk om nauwkeurig te
                      zijn en effectieve methoden te gebruiken.
                    </span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link to="/blog-left-sidebar">
                    <span className="post-control-link">
                      Volgende Blogbericht
                    </span>
                    <span className="post-name">
                      Efficiënt schoonmaken vereist consistente inspanning en de
                      juiste schoonmaakproducten.
                    </span>
                  </Link>
                </div>
              </div>

              {/* <div className="comments-area">
                <div className="comments-section">
                  <h3 className="comments-title">Comments</h3>
                  <ol className="comments">
                    <li
                      className="comment even thread-even depth-1"
                      id="comment-1"
                    >
                      <div id="div-comment-1">
                        <div className="comment-theme">
                          <div className="comment-image">
                            <img src={blog3} alt="" />
                          </div>
                        </div>
                        <div className="comment-main-area">
                          <div className="comment-wrapper">
                            <div className="comments-meta">
                              <h4>
                                John Abraham{" "}
                                <span className="comments-date">
                                  January 12,2021 At 9.00am
                                </span>
                              </h4>
                            </div>
                            <div className="comment-area">
                              <p>
                                I will give you a complete account of the
                                system, and expound the actual teachings of the
                                great explorer of the truth,{" "}
                              </p>
                              <div className="comments-reply">
                                <Link
                                  className="comment-reply-link"
                                  to="/blog-single/1"
                                >
                                  <span>Reply</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="children">
                        <li className="comment">
                          <div>
                            <div className="comment-theme">
                              <div className="comment-image">
                                <img src={blog4} alt="" />
                              </div>
                            </div>
                            <div className="comment-main-area">
                              <div className="comment-wrapper">
                                <div className="comments-meta">
                                  <h4>
                                    Lily Watson{" "}
                                    <span className="comments-date">
                                      January 12,2021 At 9.00am
                                    </span>
                                  </h4>
                                </div>
                                <div className="comment-area">
                                  <p>
                                    I will give you a complete account of the
                                    system, and expound the actual teachings of
                                    the great explorer of the truth,{" "}
                                  </p>
                                  <div className="comments-reply">
                                    <Link
                                      className="comment-reply-link"
                                      to="/blog-single/1"
                                    >
                                      <span>Reply</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul>
                            <li className="comment">
                              <div>
                                <div className="comment-theme">
                                  <div className="comment-image">
                                    <img src={blog5} alt="" />
                                  </div>
                                </div>
                                <div className="comment-main-area">
                                  <div className="comment-wrapper">
                                    <div className="comments-meta">
                                      <h4>
                                        John Abraham{" "}
                                        <span className="comments-date">
                                          January 12,2021 At 9.00am
                                        </span>
                                      </h4>
                                    </div>
                                    <div className="comment-area">
                                      <p>
                                        I will give you a complete account of
                                        the system, and expound the actual
                                        teachings of the great explorer of the
                                        truth,{" "}
                                      </p>
                                      <div className="comments-reply">
                                        <Link
                                          className="comment-reply-link"
                                          to="/blog-single/1"
                                        >
                                          <span>Reply</span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="comment">
                      <div>
                        <div className="comment-theme">
                          <div className="comment-image">
                            <img src={blog3} alt="" />
                          </div>
                        </div>
                        <div className="comment-main-area">
                          <div className="comment-wrapper">
                            <div className="comments-meta">
                              <h4>
                                John Abraham{" "}
                                <span className="comments-date">
                                  January 12,2021 At 9.00am
                                </span>
                              </h4>
                            </div>
                            <div className="comment-area">
                              <p>
                                I will give you a complete account of the
                                system, and expound the actual teachings of the
                                great explorer of the truth,{" "}
                              </p>
                              <div className="comments-reply">
                                <Link
                                  className="comment-reply-link"
                                  to="/blog-single/1"
                                >
                                  <span>Reply</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="comment-respond">
                  <h3 className="comment-reply-title">Post Comments</h3>
                  <form
                    onSubmit={submitHandler}
                    id="commentform"
                    className="comment-form"
                  >
                    <div className="form-textarea">
                      <textarea
                        id="comment"
                        placeholder="Write Your Comments..."
                      ></textarea>
                    </div>
                    <div className="form-inputs">
                      <input placeholder="Website" type="url" />
                      <input placeholder="Name" type="text" />
                      <input placeholder="Email" type="email" />
                    </div>
                    <div className="form-submit">
                      <input id="submit" value="Post Comment" type="submit" />
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
