import React from "react";
import { Link } from 'react-router-dom'


const Hero = () => {
    return (
        <section className="wpo-hero-section-1">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-5 col-lg-5 col-12">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-title">
                                <h3>Nood aan Schoonmaakdiensten?</h3>
                            </div>
                            <div className="wpo-hero-subtitle">
                            <p>Wij zijn een gecertificeerd bedrijf. Wij bieden de beste schoonmaakdiensten voor jou en je bedrijf aan.</p>
                            </div>
                            <div className="btns">
                                <Link to="/about" className="btn theme-btn"><i className="fa fa-angle-double-right"
                                    aria-hidden="true"></i>Onze Beste Aanbiedingen</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
                <div className="right-img">
                    <div className="r-img">
                        <img src={'https://cleener-react.wpocean.com/static/media/right-img.43214442.png'} alt="hero"/> 
                    </div>
                </div>
            </div>
            <div className="pop-up-video">
                <div className="video-holder">
                    {/* <VideoModal/> */}
                </div>
            </div>
        </section>
    )
}



export default Hero;