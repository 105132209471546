// Afbeeldingen
import blogAvaterImg1 from "../images/blog/blog-avater/img-1.jpg";
import blogAvaterImg2 from "../images/blog/blog-avater/img-2.jpg";
import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";


const blogs = [
    {
        id: '1',
        title: 'De Wetenschap van Voorjaarsschoonmaak!',
        screens: 'https://cleener-react.wpocean.com/static/media/img-2.ba4f2f97.jpg',
        description: 'Ontdek de wetenschap achter de voorjaarsschoonmaak en leer hoe je jouw huis fris en schoon kunt houden.',
        author: 'Jenefer Willy',
        authorTitle:'Schoonmaakleider',
        authorImg:blogAvaterImg1,
        create_at: '14 AUG,22',
        blogSingleImg:'https://cleener-react.wpocean.com/static/media/img-5.8cd0b0a4.jpg', 
        comment:'5,975',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Geld Besparen op Schoonmaakbenodigdheden',
        screens: 'https://cleener-react.wpocean.com/static/media/img-1.e6c4818e.jpg',
        description: 'Leer hoe je geld kunt besparen op schoonmaakbenodigdheden zonder in te leveren op kwaliteit.',
        author: 'Konal Biry',
        authorTitle:'Schoonmaakleider',
        authorImg:blogAvaterImg2,
        create_at: '16 AUG,22',
        blogSingleImg:'https://cleener-react.wpocean.com/static/media/img-6.4ca079d2.jpg', 
        comment:'7,275',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: '10 Huishoudelijke Reinigers in de buurt van Los Angeles',
        screens: 'https://cleener-react.wpocean.com/static/media/img-7.dd21f09f.jpg',
        description: 'Ontdek de top 10 huishoudelijke reinigers in de buurt van Los Angeles en krijg jouw huis sprankelend schoon.',
        author: 'Jenefer Willy',
        authorTitle:'Schoonmaakleider',
        authorImg:blogAvaterImg3,
        create_at: '18 AUG,22',
        blogSingleImg:'https://cleener-react.wpocean.com/static/media/img-8.813351ea.jpg',
        comment:'6,725',
        blClass:'format-standard-image',
    }
];
export default blogs;
