import sSingleimg1 from '../images/project-single/2.jpg'
import sSingleimg2 from '../images/project-single/3.jpg'



const Projects = [
    {
        Id: '1',
        pImg:'https://cleener-react.wpocean.com/static/media/img-2.a9075312.jpg',
        subTitle:'Eindhoven',
        title: 'Tapijt',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '2',
        pImg:'https://cleener-react.wpocean.com/static/media/img-3.b7b52c91.jpg',
        subTitle:'Venlo',
        title: 'Seizoensgebonden',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '3',
        pImg:'https://cleener-react.wpocean.com/static/media/img-4.c7cbf721.jpg',
        subTitle:'Echt',
        title: 'Express',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '4',
        pImg:'https://cleener-react.wpocean.com/static/media/img-5.7e10af17.jpg',
        subTitle:'Roermond',
        title: 'Keuken',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '5',
        pImg:'https://cleener-react.wpocean.com/static/media/img-1.865b3368.jpg',
        subTitle:'Echt',
        title: 'Badkamer',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
]
export default Projects;