import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/alakramLogo.png'
import Services from '../../api/service';
import Bunduk from '../../images/logo-colorpng.png' 

const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {
    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog"/>
                                </div>
                                <p>Ontdek hoe wij jouw leven gemakkelijker kunnen maken met onze professionele schoonmaakdiensten, ontworpen om jouw omgeving schoon en fris te houden.</p>


                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Link</h3>
                                </div>
                                <ul>
                                    <li><Link to="/about">Over ons </Link></li>
                                    <li><Link to="/service-s2">diensten</Link></li>
                                    <li><Link to="/appointment">Afspraak</Link></li>
                                    <li><Link to="/contact">Contact met ons </Link></li>
                                    <li><Link to="/project">Projecten</Link></li>
                                    <li><Link to="/terms">Algemene Voorwaarden</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget join-widget">
                                <div className="widget-title">
                                    <h3>Nieuwsbrief</h3>
                                </div>
                                <p>Meld je nu aan voor onze nieuwsbrief en ontdek de laatste tips, aanbiedingen en nieuws over schoonmaak.</p>
                                <form onSubmit={SubmitHandler}>
                                    <input type="email" placeholder="support@gmail.com" required/>
                                    <button type="submit">Send Now <i className="ti-arrow-right"></i></button>
                                </form>
                            </div>
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Services</h3>
                                </div>
                                <ul>
                                    {Services.slice(0,6).map((service, srv) => (
                                        <li key={srv}>{service.sTitle}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 <a href="https://www.bunduk.com/" target="_blank" rel="noopener noreferrer"><img src={Bunduk} alt='Logo' width={100}/></a> |   All Rights  Reserved</p> 
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;