import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


const FAQ = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <h2>Veelgestelde Vragen</h2>
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Marktonderzoek op ons wereldwijde panel met ondersteuning van onze experts.</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                   Planning kan helpen bij het verminderen van stress op de werkplek en het verhogen van de productiviteit.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography>Planning kan helpen bij het verminderen van stress op de werkplek en het verhogen van de productiviteit.</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Degenen die het meest experimenteren, kunnen het best innoveren.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography>Diegenen die het meest experimenteren, kunnen het best innoveren.</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Begrijp je probleem, je moet het probleem begrijpen.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography>Begrijp je probleem, je moet het probleem begrijpen.</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Inzicht hebben in je probleem is de eerste stap naar een oplossing. Door het probleem te begrijpen, kun je effectief actie ondernemen om het aan te pakken.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FAQ;
