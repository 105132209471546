import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import FAQ from './faq'
import Footer from '../../components/footer/Footer.js'

const TermPage = (props) => {
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Algemene Voorwaarden'} pagesub={'Terms & Condities'} />
            <section className="wpo-terms-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="wpo-terms-wrap">
                                <div className="wpo-terms-img">
                                    <img src={'https://cleener-react.wpocean.com/static/media/terms.de99a13b.jpg'} alt=""/>
                                </div>
                                <div className="wpo-terms-text">
                                    <h2>Redenen om Gebruiksvoorwaarden voor Websites te Gebruiken</h2>
                                    <p>Wanneer je van plan bent om een website te maken, is het heel gemakkelijk om het maken en toevoegen van een sjabloon voor gebruiksvoorwaarden voor websites over het hoofd te zien. Dit komt omdat je bij het browsen door sites dit gedeelte misschien niet bekijkt, tenzij je echt moet - en zelfs dan lees je misschien niet alle inhoud! Het is echter zeer belangrijk om de voorwaardenpagina op je website te hebben, om een aantal redenen. Zodra je alles hebt geleerd over deze redenen, realiseer je je misschien dat het toevoegen van dit onderdeel aan je website echt essentieel is.</p>
                                    <p>Zelfs een kort gebruiksvoorwaardenovereenkomst moet verschillende belangrijke clausules bevatten om je bedrijf te beschermen. Als je bijvoorbeeld online verkoopt en een item verkeerd prijst, zorgen je gebruiksvoorwaarden ervoor dat je de bestelling kunt annuleren.</p>

                                    <ul>
                                        <li>Acceptabel gebruik van je website en alle inhoud ervan.</li>
                                        <li>Regels voor het uploaden van inhoud op je website.</li>
                                        <li>Alle websites die zijn gekoppeld aan de jouwe.</li>
                                        <li>De beschikbaarheid van je website.</li>
                                        <li>Retouren en garanties voor klanten.</li>
                                    </ul>
                                    <p>Als er schattingen worden gegeven van hoe lang de schoonmaakmedewerkers erover doen om de klus te klaren, zijn dit slechts schattingen op basis van de gemiddelde tijd die nodig is om een huis of kantoor van vergelijkbare grootte als dat van de klant schoon te maken, waarbij het moeilijk is om precies te berekenen hoe lang dergelijke taken kunnen duren en dat er een zekere mate van flexibiliteit nodig kan zijn. Let op: eenmalige reinigingen kunnen langer duren vanwege langere intervallen tussen schoonmaaksessies, het aantal en het type schoonmaaktaken dat vereist is, in vergelijking met het reguliere onderhoud van dezelfde woning.</p>
                                    <div className="row t-sub">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src={'https://cleener-react.wpocean.com/static/media/1.b8bb7d0b.jpg'} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src={'https://cleener-react.wpocean.com/static/media/2.a52f7e29.jpg'} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Reiniging na bouw (bouwreiniging), evenementreiniging of slecht onderhouden huizen kunnen tot drie keer langer duren dan een goed onderhouden huis dat algemene reiniging vereist. Daarom adviseert het bedrijf de klant om te vragen naar onze gespecialiseerde reinigingsdiensten: bouwreiniging of evenementreiniging.</p>
                                </div>
                                <FAQ />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TermPage;
