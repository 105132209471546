import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HeaderTopbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(isMobile);
  return (
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col col-lg-7 col-md-5 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <i className="fi ti-location-pin"></i>Palmstraat 24, 6101KP
                  Echt
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-lg-5 col-md-7 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                <li>
                  <Link to="/contact">Steunen</Link>
                </li>
                <li>
                  <Link to="/terms">Algemene Voorwaarden</Link>
                </li>
                {/* <li className='lan-sec'>
                                    <select name="" id="">
                                        <option value="">English</option>
                                        <option value="">Spain</option>
                                        <option value="">France</option>
                                        <option value="">Italy</option>
                                    </select>
                                </li> */}
              </ul>
              {isMobile && (
                <a
                  className="theme-btn"
                  href="https://api.whatsapp.com/send/?phone=%2B31628025353&text&type=phone_number&app_absent=0"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  <span>0628025353</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
