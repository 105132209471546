import React from 'react'
import sign from '../../images/signeture.png'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={'https://cleener-react.wpocean.com/static/media/about.bbb3a134.jpg'} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-section-title-s2">
                                    <h2>Meer dan 25 jaar ervaring
                                        In Schoonmaakdiensten</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                <p>Wij zijn gespecialiseerd in het leveren van hoogwaardige schoonmaakdiensten voor zowel particuliere als zakelijke klanten. Met een toegewijd team van professionals zorgen we ervoor dat uw ruimtes er altijd schoon en fris uitzien.</p>
                                    <p>Onze diensten omvatten alles, van reguliere schoonmaak tot dieptereiniging, en we gebruiken alleen milieuvriendelijke schoonmaakproducten om een gezonde omgeving te garanderen voor u, uw medewerkers en uw klanten.</p>
                                    <div className="signeture">
                                        <h4>Brubaker Nilka</h4>
                                        <p>Alakram, CEO</p>
                                        <span><img src={sign} alt=""/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;